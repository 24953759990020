import { Injectable } from '@angular/core';
import { NbGlobalLogicalPosition, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { NbComponentStatus } from '@nebular/theme/components/component-status';


@Injectable()
export class ToastrService {
  private static DEFAULT_DURATION: number = 4000;
  private static DEFAULT_DANGER_DURATION: number = 8000;

  constructor(private toastrService: NbToastrService) {
  }

  public showInfoToast(
    message: string,
    title?: string,
    duration?: number,
    position?: NbGlobalPhysicalPosition
  ): void {
    this.showToast(
      'info',
      title ? title : 'Info.',
      message,
      duration,
      position
    );
  }

  public showWarningToast(
    message: string,
    title: string,
    duration?: number,
    position?: NbGlobalPhysicalPosition
  ): void {
    this.showToast(
      'warning',
      title ? title : 'Warning.',
      message,
      duration,
      position
    );
  }

  public showDangerToast(
    message: string,
    title?: string,
    duration?: number,
    position?: NbGlobalPhysicalPosition
  ): void {
    this.showToast(
      'danger',
      title ? title : 'Error.',
      message,
      duration ? duration : ToastrService.DEFAULT_DANGER_DURATION,
      position
    );
  }

  protected showToast(
    status: NbComponentStatus,
    title: string,
    message: string,
    duration: number,
    position: NbGlobalPhysicalPosition
  ): void {
    this.toastrService.show(
      message,
      title,
      {
        destroyByClick: true,
        duration: duration ? duration : ToastrService.DEFAULT_DURATION,
        position: position ? position : NbGlobalLogicalPosition.TOP_END,
        preventDuplicates: false,
        status: status
      });
  }
}
