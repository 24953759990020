import { NbMenuItemExt } from '../pages/access-rights/models/access-rights.models';

export class MenuItem extends NbMenuItemExt {
  id: number;
}

export interface FileFormat {
  name: string;
  maxRows: number;
}

export const FileFormats: Record<string, FileFormat> = {
  EXCEL: { name: 'EXCEL', maxRows: 65536 },
  EXCEL_XLSX: { name: 'EXCEL_XLSX', maxRows: 1048576 },
  XLS: { name: 'XLS', maxRows: 65536 },
  XLSX: { name: 'XLSX', maxRows: 1048576 },
  CSV: { name: 'CSV', maxRows: 2000000 },
  PDF: { name: 'PDF', maxRows: 500000 },
  HTML: { name: 'HTML', maxRows: 1500000 },
};

