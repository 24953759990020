import { ModuleWithProviders, NgModule, Optional, SkipSelf, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  PlayerService,
  StateService,
} from './utils';
import { UserData } from './data/users';
import { ElectricityData } from './data/electricity';
import { SmartTableData } from './data/smart-table';
import { UserActivityData } from './data/user-activity';
import { OrdersChartData } from './data/orders-chart';
import { ProfitChartData } from './data/profit-chart';
import { TrafficListData } from './data/traffic-list';
import { EarningData } from './data/earning';
import { OrdersProfitChartData } from './data/orders-profit-chart';
import { TrafficBarData } from './data/traffic-bar';
import { ProfitBarAnimationChartData } from './data/profit-bar-animation-chart';
import { TemperatureHumidityData } from './data/temperature-humidity';
import { SolarData } from './data/solar';
import { TrafficChartData } from './data/traffic-chart';
import { StatsBarData } from './data/stats-bar';
import { CountryOrderData } from './data/country-order';
import { StatsProgressBarData } from './data/stats-progress-bar';
import { VisitorsAnalyticsData } from './data/visitors-analytics';
import { SecurityCamerasData } from './data/security-cameras';

import { UserService } from './mock/users.service';
import { ElectricityService } from './mock/electricity.service';
import { SmartTableService } from './mock/smart-table.service';
import { UserActivityService } from './mock/user-activity.service';
import { OrdersChartService } from './mock/orders-chart.service';
import { ProfitChartService } from './mock/profit-chart.service';
import { TrafficListService } from './mock/traffic-list.service';
import { EarningService } from './mock/earning.service';
import { OrdersProfitChartService } from './mock/orders-profit-chart.service';
import { TrafficBarService } from './mock/traffic-bar.service';
import { ProfitBarAnimationChartService } from './mock/profit-bar-animation-chart.service';
import { TemperatureHumidityService } from './mock/temperature-humidity.service';
import { SolarService } from './mock/solar.service';
import { TrafficChartService } from './mock/traffic-chart.service';
import { StatsBarService } from './mock/stats-bar.service';
import { CountryOrderService } from './mock/country-order.service';
import { StatsProgressBarService } from './mock/stats-progress-bar.service';
import { VisitorsAnalyticsService } from './mock/visitors-analytics.service';
import { SecurityCamerasService } from './mock/security-cameras.service';
import { MockDataModule } from './mock/mock-data.module';
import { NgxAuthModule } from '../auth/auth.module';
import { NB_AUTH_TOKEN_INTERCEPTOR_FILTER, NbAuthJWTInterceptor } from '@nebular/auth';
import { HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';

import { registerLocaleData } from '@angular/common';
import localeRuUa from '@angular/common/locales/uk';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';
import localeRo from '@angular/common/locales/ro';
import { environment } from '../../environments/environment';
import { ApiInterceptor } from '../interceptors/ApiInterceptor';
import { FileLimitErrorInterceptor } from '../interceptors/FileLimitErrorInterceptor';
import { ToastrService } from '../services/toastr.service';

registerLocaleData(localeEn);
registerLocaleData(localeRuUa);
registerLocaleData(localeRu);
registerLocaleData(localeRo);

export function jwtTokenGetter(req: HttpRequest<any>) {
  if (environment.zabbix.zabbixUrl === null || environment.zabbix.zabbixUrl === '') {
    return req.url.endsWith('/oauth/token');
  } else {
    return req.url === environment.zabbix.zabbixUrl || req.url.endsWith('/oauth/token');
  }
}

// истекший токен для проверки
// {"name":"nb:auth:jwt:token","ownerStrategyName":"email1","createdAt":1587635150006,"value":"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsicGVyc29uYWxpemF0aW9uIiwiY2FyZGhvbGRlcnVpIiwidHRwcyIsImNhcmRob2xkZXIiLCJ1c2VycyJdLCJvYXV0aF9hcHBsaWNhdGlvbl9pZCI6MSwidXNlcl9pZCI6MTk5LCJwcm9qZWN0X2lkIjo1LCJ1c2VyX25hbWUiOiJvcGVyYXRvci12bi10ZXN0LWFkbWluIiwic2NvcGUiOlsicm9sZV9hZG1pbiIsInJvbGVfc3VwZXJhZG1pbiJdLCJleHAiOjE1ODc2NzgzMDUsImF1dGhvcml0aWVzIjpbInJlcG9ydCIsImFkbWluIl0sImp0aSI6Ijk2ZDRiMzg3LTg3NDAtNDk2Ny05MmFhLTJlZmEyNTgxYTkwZCIsImVtYWlsIjoib3BlcmF0b3Itdm4tdGVzdC1hZG1pbkBhc29wLnZuLnVhIiwiY2xpZW50X2lkIjoiY2FyZGhvbGRlcnVpLWFwcCJ9.neFkt00xzUfLS9kNhvd-nBB7jad9b6tNzxk2NrbhoyshMi3buVJilh97rp-WEijXdv861RRANV94VZ4gVMwpnN_6ornxDWmQLtCMcrzQD4qX5yFM5Ed7x0Esz11OYo1Ac6ls-XCw--4Jv2rUueReUUi5eood6xkWUmr2t0oTJkYYddRc6vn0m1bhzyr0LtEpHU8IWNBWNJd4Ymz15UY3YsyyPAV06kNWm_vDdL0aLe0G5x7M4ztYRFcrrlTUWr9YX8LRJTYap68s1HxtTMjSOAPV7CP0MHLq1xj5iUb0kf0-4-eukzfAZr--CIN5mJ7slXfVuK10jep70QfHLHhEmA"}

const DATA_SERVICES = [
  {provide: UserData, useClass: UserService},
  {provide: ElectricityData, useClass: ElectricityService},
  {provide: SmartTableData, useClass: SmartTableService},
  {provide: UserActivityData, useClass: UserActivityService},
  {provide: OrdersChartData, useClass: OrdersChartService},
  {provide: ProfitChartData, useClass: ProfitChartService},
  {provide: TrafficListData, useClass: TrafficListService},
  {provide: EarningData, useClass: EarningService},
  {provide: OrdersProfitChartData, useClass: OrdersProfitChartService},
  {provide: TrafficBarData, useClass: TrafficBarService},
  {provide: ProfitBarAnimationChartData, useClass: ProfitBarAnimationChartService},
  {provide: TemperatureHumidityData, useClass: TemperatureHumidityService},
  {provide: SolarData, useClass: SolarService},
  {provide: TrafficChartData, useClass: TrafficChartService},
  {provide: StatsBarData, useClass: StatsBarService},
  {provide: CountryOrderData, useClass: CountryOrderService},
  {provide: StatsProgressBarData, useClass: StatsProgressBarService},
  {provide: VisitorsAnalyticsData, useClass: VisitorsAnalyticsService},
  {provide: SecurityCamerasData, useClass: SecurityCamerasService},
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  PlayerService,
  StateService,
  ToastrService,
  {provide: LOCALE_ID, useValue: environment.language}, //your locale // uk ru en ro
];

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    //NbAuthModule,
    NgxAuthModule
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
        {provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: jwtTokenGetter},
        {
          provide: HTTP_INTERCEPTORS,
          useClass: NbAuthJWTInterceptor,
          multi: true
        },
        {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: FileLimitErrorInterceptor, multi: true},
      ],
    };
  }
}
