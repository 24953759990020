import {NbMenuItem} from '@nebular/theme';

export class NbMenuItemExt extends NbMenuItem {
  type?: number;
  role?: string;
  name?: string;
  hasChd?: boolean;
}

export interface IParams {
  page?: number;
  size?: number;
  lang?: string;
}

export class Role {
  id: number;
  name: string;
  active: number;
  description: string;
  system: boolean;
  disabled?: boolean;
  checked?: boolean;
}

export class RoutesAccess {
  id: number;
  method: string;
  path?: string;
  disabled?: boolean;
  checked?: boolean;
  name?: string;
  permission_nodes_id: number;
  lang_id: number;
  lang_short_name: string;
  node_name?: string;
  url: string;
  active: number;
  order_pos: any;
}

export class PermissionGrants {
  id: number;
  permission_nodes_id: number;
  method: string;
  url: string;
  roles: string [];
  params: ParamItemActions[];
}

export class RoutesAccessNew {
  available: RoutesAccess[];
  granted: PermissionGrants[];
}

export class PermissionGrantsRequest {
  node_id: number;
  endpoints: number[];
  roles: string[];
  params: any;
}

export class ParamItemActions {
  name: string;
  operation: string;
  values: string[];
}
