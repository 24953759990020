import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { ToastrService } from '../services/toastr.service';
import { NGXLogger } from 'ngx-logger';
import { catchError, timeout } from 'rxjs/operators';
import { FileFormat, FileFormats } from '../models/shared';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FileLimitErrorInterceptor implements HttpInterceptor {

  constructor(
    private toastrService: ToastrService,
    private translate: TranslateService,
    private logger: NGXLogger
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.logger.debug('Intercept FileLimitErrorInterceptor');
    return next.handle(req).pipe(timeout(300000), catchError(err => {
      this.logger.info('Intercepted by FileLimitErrorInterceptor');

      return this.handleFileLimitSizeError(err);
    }));
  }

  private handleFileLimitSizeError(response: HttpErrorResponse): Observable<HttpEvent<any>> {
    let url: string = response.url;
    if (response.status == 413 && url.includes('download')) {
      let format: FileFormat = this.getFormatFromUrl(url);

      this.toastrService.showDangerToast(
        this.translate.instant(
          'COMMON.TOAST.TOO_MANY_RECORDS_MESSAGE',
          {
            formatName: format.name,
            maxRowsCount: format.maxRows
          }));
    }

    return throwError(response);
  }

  private getFormatFromUrl(url: string): FileFormat {
    for (const key in FileFormats) {
      if (FileFormats[key]) {
        let format = FileFormats[key];
        if (url.endsWith(format.name)) {
          return format;
        }
      }
    }
  }
}
